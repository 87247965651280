body,
html {
  margin: 0;
  padding: 0;
}

.rootContainer {
  position: relative;
  min-height: 100vh;
}

.rootExceptFooter {
  padding-bottom: 104px;
}

.rootFooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 104px;
}
